.dashboard-container {
  padding-bottom: 50px;
  h2 {
    padding: 30px 10px;
    text-align: center;
    font-size: 3rem;
    color: $primary-color;
  }
  .empty-message {
    text-align: center;
    margin-top: 25px;
  }
  table {
    margin-block: 50px 25px;
    margin-inline: auto;
    @include fillParentWithMaxWidth(1200px);
    $cellPadding: 20px;
    thead tr {
      background: white;
      th {
        padding: $cellPadding;
      }
    }
    td {
      text-align: center;
      padding: $cellPadding;
      border-bottom: 1px solid $secondary-color;
      i {
        cursor: pointer;
        transition: all 0.5s ease-in-out;
        &:hover {
          scale: 1.25;
        }
      }
    }
  }
}
