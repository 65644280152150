.home-container {
  @include fillHeight;
  @include centerChilds;

  form {
    @include centerChilds;
    flex-direction: column;
    font-size: 2.5rem;
    @include container;
    position: relative;
    bottom: 100px;
    input,
    button,
    h3 {
      display: block;
      margin-top: 2rem;
      text-align: center;
      color: $primary-color;
    }
    h3 {
      font-size: 5rem;
    }

    input {
      font-size: 1.7rem;
      border: none;
      outline: none;
      border-radius: 10px;
      padding: 10px;
      background: $secondary-color;
      border: 2px solid $primary-color;
    }

    input {
      width: clamp(100px, 100%, 500px);
    }

    button {
      @include btnStyle;
      color: $secondary-color;
      background: $primary-color;
    }
  }
}
