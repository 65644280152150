.intro-container {
  @include fillHeight;
  display: flex;
  flex-flow: row wrap;
  position: relative;
  gap: 5%;
  @include md {
    flex-flow: column nowrap;
    gap: 50px;
    top: 75px;
  }

  .left-side {
    flex-basis: 50%;
    flex-grow: 1;
    position: relative;
    bottom: 50px;
    @include centerChilds;
    video {
      position: absolute;
      width: 100%;
      &.active {
        z-index: 1000;
      }
    }
  }
  .right-side {
    flex-basis: 40%;
    flex-grow: 1;
    color: $primary-color;
    @include centerChilds;
    flex-direction: column;
    padding-inline: 20px 50px;
    h4 {
      text-align: center;
      font-size: 3rem;
      font-style: italic;
    }
    .incoming-step {
      margin-block: 20px;
      font-size: 1.5rem;
      font-weight: bold;
      font-style: italic;
      // color: $secondary-color;
      opacity: 0.7;
    }
    button {
      @include btnStyle;
      margin-block: 10px;
      color: $primary-color;
      border: 1px solid $primary-color;
    }
  }

  &:has(.overlay) {
    .left-side,
    .right-side {
      opacity: 0;
    }
  }
  .overlay {
    position: absolute;
    inset: 0;
    color: $primary-color;
    z-index: 9999;
    @include centerChilds;

    flex-direction: column;
    font-size: 3rem;
    font-size: 3rem;
    h4 {
      text-align: center;
      font-style: italic;
    }
    p {
      margin-block: 20px;
      font-weight: bold;
      font-style: italic;
      opacity: 0.7;
    }
  }
}
