.modal-wrapper {
  visibility: hidden;
  opacity: 0;
  transition: all 0.2s ease-in-out;
  background: $overlay-bg;
  position: fixed;
  inset: 0;
  z-index: 999999;
  @include centerChilds;
  height: 100dvh;
  &.visible {
    visibility: visible;
    opacity: 1;
    .user-modal-container {
      transform: translateY(0);
    }
  }
  .user-modal-container {
    padding-inline: 20px;
    transition: all 0.2s ease-in-out;
    transform: translateY(50px);
    .main-content {
      width: 100%;
      max-width: 1200px;
      background: white;
      padding: 2rem;
      border-radius: 20px;
      overflow: hidden;
      display: flex;
      flex-flow: column nowrap;
      table {
        width: 100%;
        thead tr {
          background: $primary-color;
          color: white;
        }
        tr {
          td,
          th {
            text-align: center;
            padding: 20px;
            border-bottom: 1px solid $secondary-color;
            &:first-child {
              width: 60%;
            }
            &:nth-child(2) {
              width: 25%;
            }
            &:nth-child(3) {
              width: 10%;
            }
          }
        }
      }
      button {
        @include btnStyle;
        margin: 30px auto;
        background: $primary-color;
      }
    }
  }
}
