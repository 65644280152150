@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.2/css/all.min.css");
@import "./_shared.scss";
@import "./home";
@import "./game";
@import "./loader";
@import "./dashboard";
@import "./intro";
@import "./nav";
@import "./user-modal";
@import "./approval-code";
