.nav-component {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  padding: 10px;
  position: relative;
  z-index: 99999;

  &.flex-end {
    justify-content: flex-end;
  }
  img {
    width: 125px;
    @include tn {
      margin-inline: auto;
    }
  }
}
