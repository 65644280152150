.loader {
  background: $overlay-bg;
  position: absolute;
  inset: 0;
  @include centerChilds;
  z-index: 10000;
  flex-direction: column;
  gap: 30px;
  p {
    color: $primary-color;
    font-size: 2rem;
  }
}
