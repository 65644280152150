.game-container {
  min-height: 100dvh;
  display: flex;
  flex-flow: column;
  color: $primary-color;
  header {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;

    @include tn {
      flex-direction: column;
      justify-content: center;
    }

    div {
      padding: 20px;
      text-align: center;
      font-size: 1.75rem;
      font-style: italic;
      .title {
        font-weight: bold;
      }
    }
  }
  .question-container {
    margin-block: auto;
    @include centerChilds;
    display: flex;
    flex-direction: column;
    @include container;
    border-radius: 10px;
    font-style: italic;
    padding-inline: 25px;
    h2 {
      @include fillParentWithMaxWidth(1200px);
      overflow: hidden;
      text-align: center;
      padding: 20px 30px;
      font-size: 3rem;
      border: 2px solid $primary-color;
      border-radius: 30px;
      background: $secondary-color;
      filter: drop-shadow(7px 7px);
      span {
        font-size: 1.2rem;
        position: relative;
        bottom: 30px;
        right: 10px;
      }
    }

    .answers-container {
      @include fillParentWithMaxWidth(900px);
      margin-block: 75px 50px;
      @include centerChilds;
      flex-flow: row wrap;
      gap: 50px;
      padding-block-end: 50px;
      p {
        position: relative;
        flex-grow: 1;
        min-width: 45%;
        border-radius: 30px;
        padding: 10px 55px;
        border: 2px solid $primary-color;
        background: $secondary-color;
        text-align: center;
        opacity: 0.75;
        font-size: 2.5rem;
        font-weight: bold;
        transition: all 0.5s ease-in-out;
        @include lg {
          flex-basis: 100%;
        }
        @media (min-width: 1200px) {
          white-space: nowrap;
        }
        cursor: pointer;
        &:hover {
          background: $primary-color;
          color: $secondary-color;
        }
        img {
          position: absolute;
          right: 10px;
          width: 30px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }

  .result-container {
    min-height: 100dvh;
    @include centerChilds;
    flex-direction: column;

    @include container;
    text-align: center;
    font-style: italic;
    padding: 0;
    h2,
    button {
      margin-block: 50px;
    }

    .result {
      h2 {
        font-size: 5rem;
        flex-basis: 100%;
      }
      @include fillParentWithMaxWidth(600px);
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      div {
        padding: 20px;
        text-align: center;
        font-size: 2rem;
        .title,
        .value {
          font-weight: bold;
        }
        .value {
          margin-block: 10px;
          opacity: 0.75;
        }
      }
    }

    .qr-container {
      margin-block: auto;
      div {
        img {
          width: 100%;
          max-width: 300px;
        }
      }
      p {
        margin-block-start: 25px;
        font-size: 1.5rem;
      }
    }

    button {
      margin-top: 150px;
      font-size: inherit;
      cursor: pointer;
      padding: 15px;
      width: clamp(100px, 100%, 250px);

      @include btnStyle;
      border: 1px solid $primary-color;
      color: $primary-color;
    }
    footer {
      padding-inline: 25px;
      margin-top: auto;
      background: white;
      width: 100%;
      padding-block: 20px;
      p {
        max-width: 1200px;
        text-align: justify;
        margin-block: 10px;
        font-size: 0.75rem;
      }
    }
  }
  .question-reference {
    padding: 10px;
    text-align: center;
    font-size: 0.7rem;
    max-width: 1200px;
    margin-inline: auto;
  }
}
