.approval-code {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  text-align: center;
  @include centerChilds;
  z-index: 500000;
  span {
    padding: 10px;
    color: $primary-color;
    position: relative;
    display: block;
    writing-mode: vertical-rl;
    font-size: 0.7rem;
  }
}
