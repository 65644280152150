* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

// Colors
$primary-color: #5f308e;
$secondary-color: #d3c2df;
$bg-color: #ded0e5;
$overlay-bg: rgba(0, 0, 0, 0.5);

// Dimensions
$nav-height: 85px;

@font-face {
  font-family: digits;
  src: url("../assets/digital-7.regular.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: BwModelica;
  src: url("../assets/BwModelica-BoldItalic.otf");
  font-weight: normal;
  font-style: normal;
}

body {
  position: relative;
  overflow-x: hidden;
  background-color: $bg-color;
  background-image: url("../assets/bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top left;
  background-attachment: fixed;
  min-height: 100dvh;
  font-family: BwModelica, Arial, Helvetica, sans-serif;
}

// Media
// Small phones
$screen-tn-max: 450px;
// Small tablets and large smartphones (landscape view)
$screen-sm-max: 596px;
// Small tablets (portrait view)
$screen-md-max: 768px;
// Tablets and small desktops
$screen-lg-max: 992px;

// Phone devices
@mixin tn {
  @media (max-width: #{$screen-tn-max}) {
    @content;
  }
}

// Small devices
@mixin sm {
  @media (max-width: #{$screen-sm-max}) {
    @content;
  }
}

// Medium devices
@mixin md {
  @media (max-width: #{$screen-md-max}) {
    @content;
  }
}

// Large devices
@mixin lg {
  @media (max-width: #{$screen-lg-max}) {
    @content;
  }
}

@mixin centerChilds {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin lineClamp($line-count) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $line-count;
  line-clamp: $line-count;
  -webkit-box-orient: vertical;
}

@mixin fillHeight {
  min-height: calc(100dvh - $nav-height);
}

@mixin containerConstraint {
  max-width: 1200px;
  margin-inline: auto;
}

:root {
  font-size: 18px;
  scroll-behavior: smooth;

  @include lg {
    font-size: 18px;
  }

  @include md {
    font-size: 16px;
  }

  @include sm {
    font-size: 14px;
  }
}

@mixin container {
  padding: 30px 15px;
  width: 100%;
}

@mixin QuesionActionContainer {
  i {
    padding: 10px;
    margin: 10px;

    &:nth-child(1) {
      color: #22bb33;
    }

    &:nth-child(2) {
      color: $secondary-color;
    }

    cursor: pointer;

    &.disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }
}

@mixin fillParentWithMaxWidth($max-width) {
  width: 100%;
  max-width: $max-width;
}

@mixin btnStyle {
  margin-inline: auto;
  font-size: 1.7rem;
  border: none;
  outline: none;
  border-radius: 10px;
  padding: 10px;
  width: clamp(100px, 100%, 250px);
  cursor: pointer;
  background: $secondary-color;
  color: white;
  transition: all 0.5s ease;

  &:hover {
    scale: 1.05;
  }
}

.success {
  color: #22bb33;
}

.danger {
  color: $secondary-color;
}

.digit {
  font-family: digits;
}

button {
  font-family: BwModelica, Arial, Helvetica, sans-serif;
}
